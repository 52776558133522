import { DbTable } from "./DbTable";

export class DbColumn{

    private id:number;
    private name:string;
    private idDbTable:DbTable;

    constructor(){
        this.id = null;
        this.name = null;
        this.idDbTable = new DbTable();
    }

    public get getId():number{
        return this.id;
    }
    public set setId(id:number){
        this.id = id;
    }    

    public get getIdDbTable():DbTable{
        return this.idDbTable;
    }
    public set setIdDbTable(idDbTable:DbTable){
        this.idDbTable = idDbTable;
    }   
    
    public get getName():string{
        return this.name;
    }
    public set setName(name:string){
        this.name = name;
    }       
    

}