


export class BsnExternalSystemImplementation{

    private id:number;
    private idBsnExternalSystem:number;
    private server:string;
    private port:number;
    private protocol:string;
    private url:string;
    private codeIdBsnExternalSystem:string;

    constructor(){
        this.id = null;
        this.idBsnExternalSystem = null;
        this.server = null;
        this.port = null;
        this.protocol = null;
        this.url = null;
        this.codeIdBsnExternalSystem = null;


    }    

    public get getId():number{
        return this.id;
    }
    public set setId(id:number){
        this.id = id;
    }    
    
    public get getIdBsnExternalSystem():number{
        return this.idBsnExternalSystem;
    }
    public set setIdBsnExternalSystem(idBsnExternalSystem:number){
        this.idBsnExternalSystem = idBsnExternalSystem;
    }  

    public get getServer():string{
        return this.server;
    }
    public set setServer(server:string){
        this.server = server;
    } 

    public get getPort():number{
        return this.port;
    }
    public set setPort(port:number){
        this.port = port;
    }    

    public get getProtocol():string{
        return this.protocol;
    }
    public set setProtocol(protocol:string){
        this.protocol = protocol;
    } 

    public get getUrl():string{
        return this.url;
    }
    public set setUrl(url:string){
        this.url = url;
    } 
    
    public get getCodeIdBsnExternalSystem():string{
        return this.codeIdBsnExternalSystem;
    }
    public set setCodeIdBsnExternalSystem(codeIdBsnExternalSystem:string){
        this.codeIdBsnExternalSystem = codeIdBsnExternalSystem;
    }  
    

}