
import { AdmUser } from '@/services/access_api/models/api/1/db/AdmUser';
import router from '@/router/index';

export default {
    login: (context: any, payload: string): void => {
        context.commit('setToken', payload);
        router.replace('/');
    },
    user: (context: any, payload: AdmUser): void => {
        context.commit('setUser', payload);
    },
    logout: (context: any): void => {
        context.commit('setToken', null);
        context.commit('setUser', null);
        localStorage.removeItem('gatekeeper_token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_status');
        router.replace('/login');
    }
};
