
import {AdmOptionDbColumn} from "@/services/access_api/models/api/1/db/AdmOptionDbColumn";



export class AdmOptionDbColumnUtil{

    public static convertJsonToAdmOptionDbColumn = (json: any): AdmOptionDbColumn => {
        try {
    
            const admOptionDbcolumn:AdmOptionDbColumn = new AdmOptionDbColumn();
            admOptionDbcolumn.setId = json.id;
            admOptionDbcolumn.setName = json.name;
            admOptionDbcolumn.setIdDbColumn = json.id_db_column;
            admOptionDbcolumn.setNameCode = json.name_code;
            return admOptionDbcolumn
    
        } catch (error: any) {
           return null
        }
    };
    
    
    public static convertAdmOptionDbColumnToJson = (admOptionDbColumnToConvert: AdmOptionDbColumn): any => {
        try {
    
            
            const json = {
    
              id:admOptionDbColumnToConvert.getId,
              name:admOptionDbColumnToConvert.getName,
              name_code:admOptionDbColumnToConvert.getNameCode,
              id_db_column: admOptionDbColumnToConvert.getIdDbColumn
    
            }
            
            return json;
    
        } catch (error: any) {
           return null
        }
    };

}


