import {Options, Vue} from 'vue-class-component';
//import {loginByAuth, loginByGoogle, loginByFacebook} from '@/services/auth';
//import {loginByAuth} from '@/services/auth';
//import {admUserByUserAndPassword} from '@/services/models/db/AdmUserService';
//import {URL_BASE_UTIL_INTERN_DATA_WEB_SERVICE} from "@/config/web-service-data/Config";
//import {convertJsonToAdmUser} from "@/utils/models/api/1/db/AdmUserUtil";
//import {AdmUser} from "@/models/api/1/db/AdmUser";
import {AdmUser} from "@/services/access_api/models/api/1/db/AdmUser";
//import {AdmOptionDbColumnTable} from "@/db/table/AdmOptionDbColumnTable";
//import {DbColumn} from "@/models/db/DbColumn";
import axios from "axios";

import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {PfButton, PfCheckbox} from '@profabric/vue-components';

import { v4 as uuidv4 } from 'uuid';

import sha256 from 'crypto-js/sha256';
import { AdmUserService } from '@/services/access_api/consume/api/1/db/AdmUserService';
//import { AdmUserService } from '@ /services/models/api/1/db/AdmUserService';
//import { ApiConfig } from '@/config/ApiConfig';
import { AdmOptionDbColumnService } from '@/services/access_api/consume/api/1/db/AdmOptionDbColumnService';
import {ApiImplementationConfig} from "@/services/access_api/config/ApiImplementationConfig";

//import * as CryptoJS from 'crypto-js/bower.json';

//import VueCryptojs from 'vue-cryptojs'



//import {Gatekeeper} from "@/config/web-service-data/gatekeeper-client-sdk";

@Options({
    components: {
        'app-input': Input,
        'pf-checkbox': PfCheckbox,
        'pf-button': PfButton
    }
})
export default class Login extends Vue {


  //  private hashDigest = sha256("1234567");
    private appElement: HTMLElement | null = null;
    public email: string = '';
    public password: string = '';
    public rememberMe: boolean = false;
    public isAuthLoading: boolean = false;
    public isFacebookLoading: boolean = false;
    public isGoogleLoading: boolean = false;
    private toast = useToast();

    public mounted(): void {
      //  console.log("el valor es: "+sha256("1234567").toString())
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');

    /*    bsnBankList().then((reponse)=>{
            console.log(reponse)
        }).catch(error => {
 
         });

        console.log("reponse")
        this.toast.success('Efectuado correctamente');*/


       // const prueba = Gatekeeper.loginByAuth("computos01","12345");



     /*   axios.get(URL_BASE+"entity/bsn_bank/bsn_bank_list.php")
        .then(response => {



        console.log(response.data)

        this.toast.success('Efectuado correctamente');

        })
        .catch(error => {
           console.log("El error es: "+error);
          this.toast.error(error.message);


        })    */


       // this.loginByAuth();
     //   admUserByUserAndPassword("computos01","12345"); 

    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async loginByAuth(): Promise<void> {
        try {
            this.isAuthLoading = true;
            let validado:boolean = true;

            if(this.email == null || this.email.trim() == ""){

                this.toast.error("Debe digitar el email.");
                validado = false;

            }

            if(this.password == null || this.password.trim() == ""){

                this.toast.error("Debe digitar la contraseña.");
                validado = false;

            }     
            
            if(validado == false){
                this.isAuthLoading = false;
                return;
            }


            AdmUserService.admUserByEmailAndPassword(this.email,sha256(this.password).toString()).then((response)=>{

                if(response.getData==false){
                    this.toast.error("Datos de acceso incorrectos. Verifique su email y contraseña. ");
                    validado = false;
        
                  } else
                  if(response.getCode==500){
                    this.toast.error("Se produjo un error interno. Vuelva a intentarlo. ");
                    validado = false;
                  }
        
                  if(validado == false){
                    this.isAuthLoading = false;   
                    return;
                  }    
                  
                  

                  const token:any = uuidv4();
               //   const admUser:AdmUser = convertJsonToAdmUser(JSON.parse(JSON.stringify(response.data)));

                  AdmOptionDbColumnService.admOptionDbColumnById(response.getData.getIdStatus).then((response2)=>{

                    ApiImplementationConfig
                    if(response2.getData.getNameCode  == ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_option_db_column.data.VALUE_INACTIVO_FIELD_NAME_CODE){
                      this.toast.error("Usuario inactivo. No tiene acceso al sistema.");
                      validado = false;
          
                   }
                  //   if(response2.getData.getNameCode  == ApiConfig.API_LIST[0].database.table_list.adm_option_db_column.data.VALUE_INACTIVO_FIELD_NAME_CODE){
                  //     this.toast.error("Usuario inactivo. No tiene acceso al sistema.");
                  //     validado = false;
          
                  //  }
          
                   if(validado == false){
                      this.isAuthLoading = false;   
                      return;
                    }
               //    console.log(response);
                   localStorage.setItem("gatekeeper_token", token);
                   localStorage.setItem("user", JSON.stringify(response.getData));
                   console.log("-----------------------")
                   console.log(JSON.stringify(response.getData))
                   localStorage.setItem("user_status", JSON.stringify(response2.getData));
 //console.log(JSON.stringify(response.getData))
                   this.$store.dispatch('auth/user',response.getData);
                   this.$store.dispatch('auth/login',token);
               //    this.toast.success('Inicio de sesión exitoso');
                   this.isAuthLoading = false;

                  }).catch(error => {
                    this.toast.error(error.message);
                    this.isAuthLoading = false;             
               
                });
      
       
            }).catch(error => {
                this.toast.error(error.message);
                this.isAuthLoading = false;             
           
            });

            
        } catch (error: any) {
            this.toast.error(error.message);
            this.isAuthLoading = false;
        }
    }


}
