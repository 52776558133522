

export class ApiImplementationConfig{

   public static API_IMPLEMENTATION_CONFIG_1 = {
      "id":1,
      "type":"rest",
      "description":"API para el back end de la base de datos de la aplicaciòn padre.",
      "protocol":"https",
      "server":"parent-application-data-api-rest.api.lucho.com.do",
      "port":443,
      "url_base_application":"app/api/dtr",
      "url_base_end_point":"",
      "database": {

         "table_list":{
            "adm_option_db_column":{
               "data": 
                  {
                     "VALUE_ACTIVO_FIELD_NAME_CODE":"activo",
                     "VALUE_INACTIVO_FIELD_NAME_CODE":"inactivo"
                  }
            },
            "adm_permission":{
               "data": 
                  {
                     "VALUE_ACCESO_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE":"acceso_sistema_fidelizacion_administracion",
                     "VALUE_ACCESO_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE":"acceso_sistema_fidelizacion_clientes",
                     "VALUE_ACCESO_PAGINA_LUCHO_Y_COMPANIA_FIELD_NAME_CODE":"acceso_pagina_lucho_y_compania",
                     "VALUE_ACCESO_SISTEMA_SOLICITUD_CREDITO_FIELD_NAME_CODE":"acceso_sistema_solicitud_credito",
                     "VALUE_ACCESO_SISTEMA_REGISTRO_GARANTIA_AMICI_FIELD_NAME_CODE":"acceso_sistema_registro_garantia_amici",
                     "VALUE_ACCESO_SISTEMA_REGISTRO_GARANTIA_AMICI_ADMINISTRACION_FIELD_NAME_CODE":"acceso_sistema_registro_garantia_amici_administracion",
                     "VALUE_ACCESO_SISTEMA_GENERICOS_FIELD_NAME_CODE":"acceso_sistema_genericos",
                     "VALUE_ACCESO_SITIO_WEB_PROLAND_FIELD_NAME_CODE":"acceso_sitio_web_proland",
            }
         },
         "bsn_external_system":{
            "data": 
               {
                  "VALUE_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE":"sistema_fidelizacion_administracion",
                  "VALUE_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE":"sistema_fidelizacion_clientes",
                  "VALUE_PAGINA_LUCHO_Y_COMPANIA_FIELD_NAME_CODE":"pagina_lucho_y_compania",
                  "VALUE_SISTEMA_SOLICITUD_CREDITO_FIELD_NAME_CODE":"sistema_solicitud_credito",
                  "VALUE_SISTEMA_REGISTRO_GARANTIA_AMICI_FIELD_NAME_CODE":"sistema_registro_garantia_amici",
                  "VALUE_SISTEMA_REGISTRO_GARANTIA_AMICI_ADMINISTRACION_FIELD_NAME_CODE":"sistema_registro_garantia_amici_administracion",
                  "VALUE_SISTEMA_GENERICOS_FIELD_NAME_CODE":"sistema_genericos",
                  "VALUE_SITIO_WEB_PROLAND_FIELD_NAME_CODE":"sitio_web_proland",
         }
      }
            
         }

      }
   }; 

   public static URL_API_IMPLEMENTATION_1 = ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.protocol+"://"+ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.server+":"+ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.port+"/"+ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.url_base_application+"/"+ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.url_base_end_point;


   



}

