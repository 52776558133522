
//import {URL_BASE_UTIL_INTERN_DATA_WEB_SERVICE} from "@/config/web-service-data/Config";
import axios from "axios";
import {ApiResponseData} from "@/services/access_api/models/ApiResponseData";
//import {ApiConfig} from "@/config/ApiConfig";
import { ApiResponseDataUtil } from "@/services/access_api/utils/models/ApiResponseDataUtil";
import {AdmOptionDbColumnUtil} from "@/services/access_api/utils/models/api/1/db/AdmOptionDbColumnUtil"
import {ApiImplementationConfig} from "@/services/access_api/config/ApiImplementationConfig";
//import { AdmUserUtil } from "@/utils/models/api/1/db/AdmUserUtil";
//import { AdmUser } from "@/models/api/1/db/AdmUser";



export class AdmOptionDbColumnService{

   


      public static async admOptionDbColumnById(id:number): Promise<ApiResponseData> {

        
        const endPoint = ApiImplementationConfig.URL_API_IMPLEMENTATION_1+"/entity/adm_option_db_column/adm_option_db_column_by_id.php?id="+id;
     //   const endPoint2 = ApiConfig.API_LIST[0].protocol+ApiConfig.API_LIST[0].server+":"+ApiConfig.API_LIST[0].port+"/"+ApiConfig.API_LIST[0].url_base+"/app/api/dtr/entity/adm_option_db_column/adm_option_db_column_by_id.php?id="+id;

        return await axios.get(endPoint)
        .then(response => {
    
            let apiResponseData:ApiResponseData;
            if(response.data.data === false || response.data.data === null){

              apiResponseData = ApiResponseDataUtil.convertJsonToApiResponseData(response.data);

            }else {
              const admOptionDbColumn = AdmOptionDbColumnUtil.convertJsonToAdmOptionDbColumn(response.data.data)
              apiResponseData = ApiResponseDataUtil.convertJsonWithDataToApiResponseData(response.data,admOptionDbColumn);
            }
            return apiResponseData;
    
        })
        .catch(error => {
           console.log("El error es: "+error);
           return null;
    
        })
         
    
      }      
 


}

