import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { slot: "button" }
const _hoisted_2 = { slot: "menu" }
const _hoisted_3 = { class: "user-header bg-primary" }
const _hoisted_4 = { class: "user-footer" }

export function render(_ctx, _cache) {
  const _component_pf_image = _resolveComponent("pf-image")
  const _component_router_link = _resolveComponent("router-link")
  const _component_pf_dropdown = _resolveComponent("pf-dropdown")

  return (_openBlock(), _createBlock(_component_pf_dropdown, {
    "hide-arrow": "",
    class: "user-menu"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_pf_image, {
          src: _ctx.user && _ctx.user.picture,
          fallbackSrc: require('@/assets/img/default-profile.png'),
          class: "user-image-small",
          width: "25",
          height: "25",
          alt: "User Image",
          rounded: ""
        }, null, 8, ["src", "fallbackSrc"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_pf_image, {
            src: _ctx.user && _ctx.user.picture,
            fallbackSrc: require('@/assets/img/default-profile.png'),
            class: "user-image-big",
            alt: "User Image",
            width: "90",
            height: "90",
            rounded: ""
          }, null, 8, ["src", "fallbackSrc"]),
          _createElementVNode("p", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.user && _ctx.user.user), 1)
          ])
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/profile",
            class: "btn btn-default btn-flat",
            onClick: _cache[0] || (_cache[0] = $event => {_ctx.isDropdownOpened = false;})
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("labels.profile")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.logout && _ctx.logout(...args))),
            class: "btn btn-default btn-flat float-right"
          }, _toDisplayString(_ctx.$t("labels.signOut")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}