
//import {URL_BASE_UTIL_INTERN_DATA_WEB_SERVICE} from "@/config/web-service-data/Config";
import axios from "axios";
import {ApiResponseData} from "@/services/access_api/models/ApiResponseData";
//import {ApiConfig} from "@/config/ApiConfig";
import { ApiResponseDataUtil } from "@/services/access_api/utils/models/ApiResponseDataUtil";
import { AdmUserUtil } from "@/services/access_api/utils/models/api/1/db/AdmUserUtil";
import { AdmUser } from "@/services/access_api/models/api/1/db/AdmUser";
import {ApiImplementationConfig} from "@/services/access_api/config/ApiImplementationConfig";



export class AdmUserService{

    public static async admUserByEmailAndPassword(email:string,password:string): Promise<ApiResponseData> {

        const endPoint = ApiImplementationConfig.URL_API_IMPLEMENTATION_1+"/entity/adm_user/adm_user_by_email_and_password.php?email="+email+"&password="+password;
       // const endPoint = ApiConfig.API_LIST[0].protocol+ApiConfig.API_LIST[0].server+":"+ApiConfig.API_LIST[0].port+"/"+ApiConfig.API_LIST[0].url_base+"/app/api/dtr/entity/adm_user/adm_user_by_email_and_password.php?email="+email+"&password="+password;

        return await axios.get(endPoint)
        .then(response => {
         //   console.log(response.data)
            let apiResponseData:ApiResponseData;
            if(response.data.data === false || response.data.data === null){

              apiResponseData = ApiResponseDataUtil.convertJsonToApiResponseData(response.data);

            }else {
              const admUser = AdmUserUtil.convertJsonToAdmUser(response.data.data);
              apiResponseData = ApiResponseDataUtil.convertJsonWithDataToApiResponseData(response.data,admUser);
            }
           
            return apiResponseData;
    
        })
        .catch(error => {

           return null;
    
        })
         
    
      }


      public static async admUserByUser(user:string): Promise<ApiResponseData> {

        const endPoint = ApiImplementationConfig.URL_API_IMPLEMENTATION_1+"/entity/adm_user/adm_user_by_user.php?user="+user;
        //const endPoint = ApiConfig.API_LIST[0].protocol+ApiConfig.API_LIST[0].server+":"+ApiConfig.API_LIST[0].port+"/"+ApiConfig.API_LIST[0].url_base+"/app/api/dtr/entity/adm_user/adm_user_by_user.php?user="+user;

        return await axios.get(endPoint)
        .then(response => {
    
            let apiResponseData:ApiResponseData;
            if(response.data.data === false || response.data.data === null){

              apiResponseData = ApiResponseDataUtil.convertJsonToApiResponseData(response.data);

            }else {
              const admUser = AdmUserUtil.convertJsonToAdmUser(response.data.data);
              apiResponseData = ApiResponseDataUtil.convertJsonWithDataToApiResponseData(response.data,admUser);
            }
            return apiResponseData;
    
        })
        .catch(error => {
           console.log("El error es: "+error);
           return null;
    
        })
         
    
      }      


      public static async saveAdmUser(admUser:AdmUser): Promise<ApiResponseData> {

        const endPoint = ApiImplementationConfig.URL_API_IMPLEMENTATION_1+"/entity/adm_user/save_adm_user.php";
        //const endPoint = ApiConfig.API_LIST[0].protocol+ApiConfig.API_LIST[0].server+":"+ApiConfig.API_LIST[0].port+"/"+ApiConfig.API_LIST[0].url_base+"/app/api/dtr/entity/adm_user/save_adm_user.php";
        const admUserAsJsonString =    JSON.stringify(JSON.parse(JSON.stringify(AdmUserUtil.convertAdmUserToJson(admUser))));
        console.log(admUserAsJsonString)
        return await axios.post(endPoint,admUserAsJsonString)
        .then(response => {
             console.log(response)
            let apiResponseData:ApiResponseData;
            if(response.data.data === false || response.data.data === null){

              apiResponseData = ApiResponseDataUtil.convertJsonToApiResponseData(response.data);

            }else {
              const admUser = AdmUserUtil.convertJsonToAdmUser(response.data.data);
              apiResponseData = ApiResponseDataUtil.convertJsonWithDataToApiResponseData(response.data,admUser);
            }
            return apiResponseData;
    
        })
        .catch(error => {
           console.log("El error es: "+error);
           return null;
    
        })
         
    
      }        


}
//import {Gatekeeper} from 'gatekeeper-client-sdk';

// export const admUserByUserAndPassword = async (user: string, password: string) => {
//     try {

         
//       await axios.get(URL_BASE+"entity/adm_user/adm_user_by_user_and_password.php?user="+user+"&password="+password)
//         .then(response => {

//         console.log(response.data)
//         })
//         .catch(error => {
//           console.log(error)

//         })


//      /*   const val = await 5;
//         console.log("ejecutado: "+val);
//         return "ejecutado: "+val;*/

//       /*  const token = await Gatekeeper.loginByAuth(user, password);
//         return token;*/
//     } catch (error: any) {
//         console.log("Error: "+error);
//        return null
//     }
// };

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*import {Gatekeeper} from 'gatekeeper-client-sdk';
const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const loginByAuth = async (email: string, password: string) => {
    try {
        const token = await Gatekeeper.loginByAuth(email, password);
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getProfile = async () => {
    try {
        const user = await Gatekeeper.getProfile();
        return user;
    } catch (error: any) {
        throw getError(error);
    }
};*/

/*export const registerByAuth = async (email: string, password: string) => {
    try {
        const token = await Gatekeeper.registerByAuth(email, password);
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const loginByGoogle = async () => {
    try {
        const token = await Gatekeeper.loginByGoogle();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByGoogle = async () => {
    try {
        const token = await Gatekeeper.registerByGoogle();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const loginByFacebook = async () => {
    try {
        const token = await Gatekeeper.loginByFacebook();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByFacebook = async () => {
    try {
        const token = await Gatekeeper.registerByFacebook();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};*/

