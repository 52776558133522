export class DbTable{

    private id:number;
    private name:string;

    constructor(){
        this.id = null;
        this.name = null;
    }

    public get getId():number{
        return this.id;
    }
    public set setId(id:number){
        this.id = id;
    }    

    public get getName():string{
        return this.name;
    }
    public set setName(name:string){
        this.name = name;
    }     
    

}