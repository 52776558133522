
//import {ApiConfig} from "@/config/ApiConfig";
import { ApiResponseData } from "@/services/access_api/models/ApiResponseData";
import { ApiResponseDataUtil } from "@/services/access_api/utils/models/ApiResponseDataUtil";
import axios from "axios";
import {ApiImplementationConfig} from "@/services/access_api/config/ApiImplementationConfig";

export class AdmUserAdmPermissionService{

    public static async idAdmUserHasNameCodeAdmPermission(idAdmUser:number,nameCodeAdmPermission:string): Promise<ApiResponseData> {

        const endPoint = ApiImplementationConfig.URL_API_IMPLEMENTATION_1+"/entity/adm_user_adm_permission/id_adm_user_has_name_code_adm_permission.php?id_adm_user="+idAdmUser+"&name_code_adm_permission="+nameCodeAdmPermission;
     //   const endPoint =ApiConfig.API_LIST[0].protocol+ ApiConfig.API_LIST[0].server+":"+ApiConfig.API_LIST[0].port+"/"+ApiConfig.API_LIST[0].url_base+"/app/api/dtr/entity/adm_user_adm_permission/id_adm_user_has_name_code_adm_permission.php?id_adm_user="+idAdmUser+"&name_code_adm_permission="+nameCodeAdmPermission;
     //   console.log("En end point: "+endPoint)
        return await axios.get(endPoint)
        .then(response => {
    
         //   const admUser = AdmUserUtil.convertJsonToAdmUser(response.data.data);Console.log()
       //  console.log(idAdmUser+", "+nameCodeAdmPermission)
            const apiResponseData:ApiResponseData = ApiResponseDataUtil.convertJsonToApiResponseData(response.data);
            return apiResponseData;
    
        })
        .catch(error => {
           console.log("El error es: "+error);
           return null;
    
        })
         
    
      }


}
/*
export async function idAdmUserHasNameCodeAdmPermission(idAdmUser:number,nameCodeAdmPermission:string): Promise<number> {

    return await axios.get(URL_BASE_UTIL_INTERN_DATA_WEB_SERVICE+"entity/adm_user_adm_permission/id_adm_user_has_name_code_adm_permission.php?id_adm_user="+idAdmUser+"&name_code_adm_permission="+nameCodeAdmPermission)
    .then(response => {

        const idAdmUserHasNameCodeAdmPermission: number =  response.data;
        return idAdmUserHasNameCodeAdmPermission;
 
    })
    .catch(error => {
       console.log("El error es: "+error);
       return null;

    })
     

  }*/