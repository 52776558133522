export class ApiResponseData{

    private data:any;
    private code:number;
    private errorMessage:string;


    constructor(){
        this.data = null;
        this.code = null;
        this.errorMessage = null;
    }   


    public get getData():any{
        return this.data;
    }
    public set setData(data:any){
        this.data = data;
    }  

    public get getCode():any{
        return this.code;
    }
    public set setCode(code:any){
        this.code = code;
    }  

    public get getErrorMessage():any{
        return this.errorMessage;
    }
    public set setErrorMessage(errorMessage:any){
        this.errorMessage = errorMessage;
    } 




}