//import {AdmUser} from "@/models/api/1/db/AdmUser";
import {AdmUser} from "@/services/access_api/models/api/1/db/AdmUser";



export class AdmUserUtil{

    public static convertJsonAdmUserToAdmUser = (json: any): AdmUser => {
        try {
    
          
            const admUser:AdmUser = new AdmUser();
            admUser.setId = json.id;
            admUser.setName = json.name;
            admUser.setUser = json.user;
            admUser.setPassword = json.password;
            admUser.setEmail = json.email;
    
            admUser.setIdStatus = json.idStatus;
           // admUser.setIdStatusName = json.id_status_name;
            //admUser.setIdStatusNameCode = json.id_status_name_code;
    
            admUser.setIdAdmUser = null;
            admUser.setDateTimeRegister = json.dateTimeRegister;
    
            return admUser
    
        } catch (error: any) {
           return null
        }
    };    
    
public static convertJsonToAdmUser = (json: any): AdmUser => {
    try {

      
        const admUser:AdmUser = new AdmUser();
        admUser.setId = json.id;
        admUser.setName = json.name;
        admUser.setUser = json.user;
        admUser.setPassword = json.password;
        admUser.setEmail = json.email;

        admUser.setIdStatus = json.id_status;
       // admUser.setIdStatusName = json.id_status_name;
        //admUser.setIdStatusNameCode = json.id_status_name_code;

        admUser.setIdAdmUser = null;
        admUser.setDateTimeRegister = json.date_time_register;

        return admUser

    } catch (error: any) {
       return null
    }
};



public static convertAdmUserToJson = (admUser: AdmUser): any => {
    try {

        
        const json = {

          id:admUser.getId,
          name:admUser.getName,
          user:admUser.getUser,
          password:admUser.getPassword,
          email:admUser.getEmail,
          id_status: admUser.getIdStatus,
          date_time_register: admUser.getDateTimeRegister

        }
        
        return json;

    } catch (error: any) {
       return null
    }
};


}

