//import {BsnExternalSystemImplementation} from "@/models/api/1/db/BsnExternalSystemImplementation";
import {BsnExternalSystemImplementation} from "@/services/access_api/models/api/1/db/BsnExternalSystemImplementation";


export class BsnExternalSystemImplementationUtil{

    public static convertJsonBsnExternalSystemImplementationToBsnExternalSystemImplementation = (json: any): BsnExternalSystemImplementation => {
        try {
    
            const bsnExternalSystemImplementation:BsnExternalSystemImplementation = new BsnExternalSystemImplementation();
            bsnExternalSystemImplementation.setId = json.id;
            bsnExternalSystemImplementation.setIdBsnExternalSystem = json.id_bsn_external_system;
            bsnExternalSystemImplementation.setServer = json.server;
            bsnExternalSystemImplementation.setPort = json.port;
            bsnExternalSystemImplementation.setProtocol = json.protocol;   
            bsnExternalSystemImplementation.setUrl = json.url;
            bsnExternalSystemImplementation.setCodeIdBsnExternalSystem = json.code_id_bsn_external_system ;
    
            return bsnExternalSystemImplementation
    
        } catch (error: any) {
           return null
        }
    };    
    
public static convertJsonToBsnExternalSystemImplementation = (json: any): BsnExternalSystemImplementation => {
    try {

      
        const bsnExternalSystemImplementation:BsnExternalSystemImplementation = new BsnExternalSystemImplementation();
        bsnExternalSystemImplementation.setId = json.id;
        bsnExternalSystemImplementation.setIdBsnExternalSystem = json.id_bsn_external_system;
        bsnExternalSystemImplementation.setServer = json.server;
        bsnExternalSystemImplementation.setPort = json.port;
        bsnExternalSystemImplementation.setProtocol = json.protocol;   
        bsnExternalSystemImplementation.setUrl = json.url;
        bsnExternalSystemImplementation.setCodeIdBsnExternalSystem = json.code_id_bsn_external_system ;

        return bsnExternalSystemImplementation

    } catch (error: any) {
       return null
    }
};



public static convertBsnExternalSystemImplementationToJson = (bsnExternalSystemImplementation: BsnExternalSystemImplementation): any => {
    try {

        
        const json = {

          id:bsnExternalSystemImplementation.getId,
          id_bsn_external_system:bsnExternalSystemImplementation.getIdBsnExternalSystem,
          server:bsnExternalSystemImplementation.getServer,
          port:bsnExternalSystemImplementation.getPort,
          protocol:bsnExternalSystemImplementation.getProtocol,
          url: bsnExternalSystemImplementation.getUrl,
          code_id_bsn_external_system: bsnExternalSystemImplementation.getCodeIdBsnExternalSystem

        }
        
        return json;

    } catch (error: any) {
       return null
    }
};


}

