import {Options, Vue} from 'vue-class-component';
import Multiselect from 'vue-multiselect';

import "vue-multiselect/dist/vue-multiselect.css";

@Options({


    name: 'dashboard',
    components: {
        "multiselect":Multiselect
    }


})
export default class Dashboard extends Vue {

  public mounted(): void {
   // window.location.href = "https://www.google.com/";
}


/*
    private value = { name: 'Vue.js', language: 'JavaScript' };
    private options = [
      { name: 'Vue.js', language: 'JavaScript' },
      { name: 'Rails', language: 'Ruby' },
      { name: 'Sinatra', language: 'Ruby' },
      { name: 'Laravel', language: 'PHP' },
      { name: 'Phoenix', language: 'Elixir' }
    ]
  
    public nameWithLang ({ name, language }: { name: number; language: string }) {
      return `${name} — [${language}]`
    }
  
*/
}
