//import { AdmOptionDbColumn } from "@/services/access_api/models/api/1/db/AdmOptionDbColumn";


export class AdmUser{

    private id:number;
    private name:string;
    private user:string;
    private password:string;
    private email:string;

    private idStatus:number;
    private idStatusName:string;
    private idStatusNameCode:string;

    private idAdmUser:number;
    private dateTimeRegister:string;
    constructor(){
        this.id = null;
        this.name = null;
        this.user = null;
        this.password = null;
        this.email = null;
        this.idStatus = null;
        this.idAdmUser = null;
        this.dateTimeRegister = null;


    }

    public get getIdStatusName():string{
        return this.idStatusName;
    }
    public set setIdStatusName(idStatusName:string){
        this.idStatusName = idStatusName;
    }  

    public get getIdStatusNameCode():string{
        return this.idStatusNameCode;
    }
    public set setIdStatusNameCode(idStatusNameCode:string){
        this.idStatusNameCode = idStatusNameCode;
    }      

    public get getId():number{
        return this.id;
    }
    public set setId(id:number){
        this.id = id;
    }    
    
    public get getName():string{
        return this.name;
    }
    public set setName(name:string){
        this.name = name;
    } 

    public get getUser():string{
        return this.user;
    }
    public set setUser(user:string){
        this.user = user;
    } 

    public get getPassword():string{
        return this.password;
    }
    public set setPassword(password:string){
        this.password = password;
    } 
    
    public get getEmail():string{
        return this.email;
    }
    public set setEmail(email:string){
        this.email = email;
    }  
    
    public get getIdStatus():number{
        return this.idStatus;
    }
    public set setIdStatus(idStatus:number){
        this.idStatus = idStatus;
    }      

    public get getIdAdmUser():number{
        return this.idAdmUser;
    }
    public set setIdAdmUser(idAdmUser:number){
        this.idAdmUser = idAdmUser;
    }   
    
    public get getDateTimeRegister():string{
        return this.dateTimeRegister;
    }
    public set setDateTimeRegister(dateTimeRegister:string){
        this.dateTimeRegister = dateTimeRegister;
    }    

}