import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import { AdmUser } from '@/services/access_api/models/api/1/db/AdmUser';
import {AdmUserUtil} from "@/services/access_api/utils/models/api/1/db/AdmUserUtil";

/*
import { AdmUser } from '@/models/api/1/db/AdmUser';
import {AdmUserUtil} from "@/utils/models/api/1/db/AdmUserUtil";

*/

import {useToast} from 'vue-toastification';
import axios from "axios";
//import {URL_BASE_UTIL_INTERN_DATA_WEB_SERVICE} from "@/config/web-service-data/Config";


import { AdmUserService } from '@/services/access_api/consume/api/1/db/AdmUserService';

import sha256 from 'crypto-js/sha256';
//import { AdmUserService } from '@/services/models/api/1/db/AdmUserService';

@Options({
    name: 'app-profile',
    components: {
        'pf-image': PfImage
    }
})
export default class Profile extends Vue {
    private activeTab = 'SETTINGS';
    private currentPassword:string = "";
    private newPassword:string = "";
    private repeatNewPassword:string = "";

    private toast = useToast();

    private setActiveTab(tab: string): void {
        this.activeTab = tab;
    }

    public mounted(): void {
      
      /*  let validado:boolean = true;
        axios.get(URL_BASE+"entity/adm_user/adm_user_by_user.php?user="+this.user.getUser)
        // await axios.get(URL_BASE+"entity/adm_user/adm_user_by_email_and_password.php?email="+"computos01@lucho.com.do"+"&password="+"12345")
           .then(response => {
  
            

            const responseData:any = response.data;
            if(responseData==false){
              this.toast.error("Este usuario no se ha encontrado. ");
              validado = false;
  
            } else
            if(responseData==null){
              this.toast.error("Se produjo un error interno. Vuelva a intentarlo. ");
              validado = false;
            }
  
            if(validado == false){
              return;
            }

            const admUser:AdmUser = convertJsonToAdmUser(JSON.parse(JSON.stringify(response.data)));
            this.newPassword = "98765";

            admUser.setPassword = this.newPassword;


            const admUserJSon = JSON.parse(JSON.stringify(response.data));
            admUserJSon.password = this.newPassword;

            console.log(JSON.stringify(admUserJSon));
            this.toast.success('Ejecutado.');


             

           })
           .catch(error => {

             this.toast.error(error.message);

   
           })   */


    }

    get user(): AdmUser {

   //   console.log(JSON.parse(localStorage.getItem('user')))
          // console.log(AdmUserUtil.convertJsonToAdmUser(JSON.parse(localStorage.getItem('user'))))
          return AdmUserUtil.convertJsonAdmUserToAdmUser(JSON.parse(localStorage.getItem('user')));



      }

      get userStatus(): AdmUser {

        //  console.log(JSON.parse(localStorage.getItem('user')))
              // console.log(AdmUserUtil.convertJsonToAdmUser(JSON.parse(localStorage.getItem('user'))))
              return JSON.parse(localStorage.getItem('user_status'));
    
          }      

    public changePassword(): void {




        let validado:boolean = true;
      
     //  const userValue = localStorage.getItem('user');
       console.log(this.user)
        AdmUserService.admUserByUser(this.user.getUser).then((response)=>{
  
       //   const responseData:any = response.data;
          if(response.getData==false){
            this.toast.error("Este usuario no se ha encontrado. "+this.user.getUser);
            validado = false;

          } else
          if(response.getCode==500){
            this.toast.error("Se produjo un error interno. Vuelva a intentarlo. ");
            validado = false;
          }

          if(validado == false){
            return;
          }

      //    const admUser:AdmUser = AdmUserUtil.convertJsonToAdmUser(JSON.parse(JSON.stringify(response.data)));
    
          if(response.getData.getPassword != sha256(this.currentPassword).toString()){
              this.toast.error("La contraseña actual es incorrecta. ");
              validado = false;

          }

          if(this.newPassword != this.repeatNewPassword){
              this.toast.error("La nueva contraseña y la nueva contraseña repetida no son iguales. ");
              validado = false;
          }    
          
          if(validado == false){
              return;
            }     
            
          if(this.newPassword.length < 7){
              this.toast.error("La nueva contraseña debe tener por lo menos 7 carácteres. ");
              validado = false;
          }             

          if(validado == false){
              return;
          }     
          
          response.getData.setPassword = sha256(this.newPassword).toString();

          AdmUserService.saveAdmUser(response.getData).then((response2)=>{


           // console.log(response2)
         //   const response2Data:any = response2.data;
            if(response2.getData==false){
              this.toast.error("No se pudo cambiar la contraseña. Vuelva a intentarlo.  ");
              validado = false;
  
            } else
            if(response2.getCode==500){
              this.toast.error("Se produjo un error interno. Vuelva a intentarlo. ");
              validado = false;
            }
  
            if(validado == false){
              return;
            }


            localStorage.removeItem('user');
            localStorage.setItem("user", JSON.stringify(response2.getData));

            this.currentPassword = "";
            this.newPassword = "";
            this.repeatNewPassword = "";                
            this.toast.success('Contraseña cambiada correctamente.');            

          }).catch(error => {
     
            this.toast.error(error.message);
       
        });



        }).catch(error => {
     
          this.toast.error(error.message);
     
      });

  

    }




}
