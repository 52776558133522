import { ApiResponseData } from "@/services/access_api/models/ApiResponseData";
import {AdmUser} from "@/services/access_api/models/api/1/db/AdmUser";
//import {AdmUser} from "@/models/api/1/db/AdmUser";
//import {convertAdmOptionDbColumnToJson, convertJsonToAdmOptionDbColumn} from "@/utils/models/api/1/db/AdmOptionDbColumnUtil";


export class ApiResponseDataUtil{



    public static convertJsonWithDataToApiResponseData = (json: any,data:any): ApiResponseData => {
        try {
    
            
            const apiResponseData:ApiResponseData = new ApiResponseData();
            apiResponseData.setData = data;
            apiResponseData.setCode = json.code;
            apiResponseData.setErrorMessage = json.error_message;
            return apiResponseData
    
        } catch (error: any) {
           return null
        }
    };

    public static convertJsonToApiResponseData = (json: any): ApiResponseData => {
        try {
    
            
            const apiResponseData:ApiResponseData = new ApiResponseData();
            apiResponseData.setData = json.data;
            apiResponseData.setCode = json.code;
            apiResponseData.setErrorMessage = json.error_message;
            return apiResponseData
    
        } catch (error: any) {
           return null
        }
    };
    
    
    
    public static convertApiResponseDataToJson = (apiResponseData: ApiResponseData): any => {
        try {
    
            
            const json = {
    
              data:apiResponseData.getData,
              code:apiResponseData.getCode,
              error_message:apiResponseData.getErrorMessage,
    
            }
            
            return json;
    
        } catch (error: any) {
           return null
        }
    };

}

