//import { DbColumn } from "./DbColumn";
import { DbColumn } from "@/services/access_api/models/api/1/db/DbColumn";

export class AdmOptionDbColumn{

    private id:number;
    private name:string;
    private nameCode:string;
    private idDbColumn:DbColumn;

    constructor(){
        this.id = null;
        this.name = null;
        this.nameCode = null;

        this.idDbColumn = new DbColumn();
    }

    public get getId():number{
        return this.id;
    }
    public set setId(id:number){
        this.id = id;
    }    

    public get getIdDbColumn():DbColumn{
        return this.idDbColumn;
    }
    public set setIdDbColumn(idDbColumn:DbColumn){
        this.idDbColumn = idDbColumn;
    }   
    
    public get getName():string{
        return this.name;
    }
    public set setName(name:string){
        this.name = name;
    } 

    public get getNameCode():string{
        return this.nameCode;
    }
    public set setNameCode(nameCode:string){
        this.nameCode = nameCode;
    }   
    

}