//import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import MenuItem from '@/components/menu-item/menu-item.vue';
import {PfImage} from '@profabric/vue-components';
import SidebarSearch from '@/components/sidebar-search/sidebar-search.vue';
import {i18n} from '@/translation';
//import { AdmUser } from '@/models/api/1/db/AdmUser';
import { AdmUser } from '@/services/access_api/models/api/1/db/AdmUser';





// import {AdmUserUtil} from "@/utils/models/api/1/db/AdmUserUtil";
// import { AdmUserAdmPermissionService } from '@/services/models/api/1/db/AdmUserAdmPermissionService';
// import { BsnExternalSystemImplementationService } from '@/services/models/api/1/db/BsnExternalSystemImplementationService';


import {AdmUserUtil} from "@/services/access_api/utils/models/api/1/db/AdmUserUtil";
import { AdmUserAdmPermissionService } from '@/services/access_api/consume/api/1/db/AdmUserAdmPermissionService';
import { BsnExternalSystemImplementationService } from '@/services/access_api/consume/api/1/db/BsnExternalSystemImplementationService';


//import {ApiConfig } from '@/config/ApiConfig';
import {ApiImplementationConfig} from "@/services/access_api/config/ApiImplementationConfig";

import {  ref } from "vue";

@Options({
    name: 'app-menu-sidebar',
    components: {
        'app-menu-item': MenuItem,
        'app-sidebar-search': SidebarSearch,
        'pf-image': PfImage
    }
})
export default class MenuSidebar extends Vue {
    public menu:any = [
        {
        //    position: 1,
            name: i18n.global.t('labels.dashboard'),
            open_new_tab: false,
            path: '/',
            iconClass: "nav-icon fas fa-home"
        }

    ];

    public quantityItemsCompletedLoad:number;

    private slyleMenuOptions:any = ref("");

    get user(): AdmUser {


        return AdmUserUtil.convertJsonToAdmUser(JSON.parse(localStorage.getItem('user')));

    }

    get sidebarSkin() {
        return this.$store.getters['ui/sidebarSkin'];
    }

    get menuSidebarCollapsed() {
        return this.$store.getters['ui/menuSidebarCollapsed'];
    }

    get screenSize() {
        return this.$store.getters['ui/screenSize'];
    }

    
    public sortItemList():void{


        if(this.quantityItemsCompletedLoad === 6){


            this.menu.sort(function(a, b){
                
                if(b.position>a.position)return -1;

                else

                if(b.position<a.position)return 1;

                else return 0
            
            
            });
/*
          for(let a=0;a<this.menu.length;a++){
            console.log("Posicion: "+this.menu[a].position);
          }

            console.log("f");*/



        }

    }

    public mounted(): void {
/*
        if(!this.menuSidebarCollapsed && this.screenSize == 'lg' ){
        //    this.$store.dispatch('ui/toggleMenuSidebar');
        }*/
      //  this.$store.dispatch('ui/toggleMenuSidebar');
        
    }

    

    public created(): void {


        const idAdmUser:number = this.user.getId;
        this.quantityItemsCompletedLoad = 0;

        let paginaLuchoYCompania:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_PAGINA_LUCHO_Y_COMPANIA_FIELD_NAME_CODE).then((reponse)=>{
       
            paginaLuchoYCompania = reponse.getData; 
            if(paginaLuchoYCompania == 1){

                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_PAGINA_LUCHO_Y_COMPANIA_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const paginaLuchoYCompaniaList =                        
                    {
                       position: 2,
                       name: "Lucho & Compañía",
                       open_new_tab: true,
                       path:linkAcceso,
                 
                       iconClass: "nav-icon fas fa-truck"
                   }

                   this.menu.push(

                    paginaLuchoYCompaniaList


                   )   
                   
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            paginaLuchoYCompania = null;
        
        });


        let sistemaGenericos:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_GENERICOS_FIELD_NAME_CODE).then((reponse)=>{
       
            sistemaGenericos = reponse.getData; 
            if(sistemaGenericos == 1){

                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SISTEMA_GENERICOS_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const sistemaGenericosList =                        
                    {
                       position: 3, 
                       name: "Sistemas Genéricos ",
                       open_new_tab: true,
                       path:linkAcceso,
                     
                       iconClass: "nav-icon fas fa-object-ungroup"
                   }
                //   console.log("Septimo")
                   this.menu.push(

                    sistemaGenericosList


                   )       
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();                         

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            sistemaGenericos = null;
        
        });           


        let sistemaSolicitudCredito:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_SOLICITUD_CREDITO_FIELD_NAME_CODE).then((reponse)=>{
       
            sistemaSolicitudCredito = reponse.getData; 
            if(sistemaSolicitudCredito == 1){

                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SISTEMA_SOLICITUD_CREDITO_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const sistemaSolicitudCreditoList =                        
                    {
                       position: 4, 
                       name: "Solicitud de Crédito",
                       open_new_tab: true,
                       path:linkAcceso,
  
                       iconClass: "nav-icon fas fa-credit-card"
                   }

                   this.menu.push(

                    sistemaSolicitudCreditoList


                   )    
                   
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();                   

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            sistemaSolicitudCredito = null;
        
        });    
        
       
        let sitioWebProland:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SITIO_WEB_PROLAND_FIELD_NAME_CODE).then((reponse)=>{
         
            sitioWebProland = reponse.getData; 
            if(sitioWebProland == 1){
      
                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SITIO_WEB_PROLAND_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const sitioWebProlandList =                        
                    {
                       position: 5, 
                       name: "Proland",
                       open_new_tab: true,
                       path:linkAcceso,
                     
                       iconClass: "nav-icon fas fa-tint"
                   }
            
                   this.menu.push(

                    sitioWebProlandList


                   ) 
                   
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();                   

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            sitioWebProland = null;
        
        });



        let programaFidelizacionAdministracion:number;
        let programaFidelizacionClientes:number;
        
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE).then((reponse)=>{
            
            programaFidelizacionAdministracion = reponse.getData; 

            AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE).then((reponse2)=>{
                
                programaFidelizacionClientes = reponse2.getData;

                if(programaFidelizacionAdministracion == 1 || programaFidelizacionClientes == 1){

                    BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE,"1").then((response3)=>{

                        BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE,"1").then((response4)=>{

                            const programaFidelizacionListChilden:any = [];
                            if(programaFidelizacionAdministracion == 1 && programaFidelizacionClientes == 1){

                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    programaFidelizacionListChilden.push({
                                        name: "Clientes",
                                        path: linkAcceso
                                    });
        
                                }

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    programaFidelizacionListChilden.push({
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                



                            }else
                            if(programaFidelizacionAdministracion == 1){

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    programaFidelizacionListChilden.push({
                                        position: 8, 
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                   
         
                            }else{
                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    programaFidelizacionListChilden.push({
                                        position: 7, 
                                        name: "Clientes",
                                        path: linkAcceso
                                    });
        
                                }         
         

                            }      
                            
                            
                            const programaFidelizacionList =                        
                            {
                               position: 6, 
                               name: "Programa Fidelización",
                               
                               children: programaFidelizacionListChilden,
                               iconClass: "nav-icon fas fa-map"
                           }
             
                           this.menu.push(
       
                               programaFidelizacionList
       
       
                           )                            
                           this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                           this.sortItemList();
                        
                        }).catch(error => {
                            console.log(error)
                        });
                        
                    }).catch(error => {
                        console.log(error)
                    
                    });


                    }

            


       
       
             }).catch(error => {

        
                programaFidelizacionClientes = null;
            
            });


   
        }).catch(error => {
   
            programaFidelizacionAdministracion = null; 
       
        });




        let sistemaRegistroGarantiaAmiciAdministracion:number;
        let sistemaRegistroGarantiaAmiciClientes:number;
        
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_REGISTRO_GARANTIA_AMICI_ADMINISTRACION_FIELD_NAME_CODE).then((reponse)=>{
            
            sistemaRegistroGarantiaAmiciAdministracion = reponse.getData; 

            AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_REGISTRO_GARANTIA_AMICI_FIELD_NAME_CODE).then((reponse2)=>{
                
                sistemaRegistroGarantiaAmiciClientes = reponse2.getData;

                if(sistemaRegistroGarantiaAmiciAdministracion == 1 || sistemaRegistroGarantiaAmiciClientes == 1){

                    BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SISTEMA_REGISTRO_GARANTIA_AMICI_FIELD_NAME_CODE,"1").then((response3)=>{

                        BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiImplementationConfig.API_IMPLEMENTATION_CONFIG_1.database.table_list.bsn_external_system.data.VALUE_SISTEMA_REGISTRO_GARANTIA_AMICI_ADMINISTRACION_FIELD_NAME_CODE,"1").then((response4)=>{

                            const sistemaRegistroGarantiaAmiciListChilden:any = [];
                            if(sistemaRegistroGarantiaAmiciAdministracion == 1 && sistemaRegistroGarantiaAmiciClientes == 1){

                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        name: "Registro",
                                        path: linkAcceso
                                    });
        
                                }

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                



                            }else
                            if(sistemaRegistroGarantiaAmiciAdministracion == 1){

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        position: 11, 
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                   
         
                            }else{
                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        position: 10, 
                                        name: "Registro",
                                        path: linkAcceso
                                    });
        
                                }         
         
                            }      
                            
                            
                            const sistemaRegistroGarantiaAmiciList =                        
                            {
                               position: 9, 
                               name: "Garantia AMICI",
                               
                               children: sistemaRegistroGarantiaAmiciListChilden,
                               iconClass: "nav-icon fas fa-map"
                           }
                        //   console.log("Septimo")
                           this.menu.push(
       
                            sistemaRegistroGarantiaAmiciList
       
       
                           )                            

                           this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                           this.sortItemList();                        


                        }).catch(error => {
                            console.log(error)
                        });
                        
                    }).catch(error => {
                        console.log(error)
                    
                    });


                    }

            


       
       
             }).catch(error => {

        
                sistemaRegistroGarantiaAmiciClientes = null;
            
            });


   
        }).catch(error => {
   
            sistemaRegistroGarantiaAmiciAdministracion = null; 
       
        });
  


    }
}

 /*   


public created(): void {


        const idAdmUser:number = this.user.getId;
        this.quantityItemsCompletedLoad = 0;

        let paginaLuchoYCompania:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_PAGINA_LUCHO_Y_COMPANIA_FIELD_NAME_CODE).then((reponse)=>{
       
            paginaLuchoYCompania = reponse.getData; 
            if(paginaLuchoYCompania == 1){

                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_PAGINA_LUCHO_Y_COMPANIA_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const paginaLuchoYCompaniaList =                        
                    {
                       position: 2,
                       name: "Lucho & Compañía",
                       open_new_tab: true,
                       path:linkAcceso,
                 
                       iconClass: "nav-icon fas fa-truck"
                   }

                   this.menu.push(

                    paginaLuchoYCompaniaList


                   )   
                   
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            paginaLuchoYCompania = null;
        
        });


        let sistemaGenericos:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_GENERICOS_FIELD_NAME_CODE).then((reponse)=>{
       
            sistemaGenericos = reponse.getData; 
            if(sistemaGenericos == 1){

                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_GENERICOS_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const sistemaGenericosList =                        
                    {
                       position: 3, 
                       name: "Sistemas Genéricos ",
                       open_new_tab: true,
                       path:linkAcceso,
                     
                       iconClass: "nav-icon fas fa-object-ungroup"
                   }
                //   console.log("Septimo")
                   this.menu.push(

                    sistemaGenericosList


                   )       
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();                         

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            sistemaGenericos = null;
        
        });           


        let sistemaSolicitudCredito:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_SOLICITUD_CREDITO_FIELD_NAME_CODE).then((reponse)=>{
       
            sistemaSolicitudCredito = reponse.getData; 
            if(sistemaSolicitudCredito == 1){

                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_SOLICITUD_CREDITO_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const sistemaSolicitudCreditoList =                        
                    {
                       position: 4, 
                       name: "Solicitud de Crédito",
                       open_new_tab: true,
                       path:linkAcceso,
  
                       iconClass: "nav-icon fas fa-credit-card"
                   }

                   this.menu.push(

                    sistemaSolicitudCreditoList


                   )    
                   
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();                   

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            sistemaSolicitudCredito = null;
        
        });    
        
       
        let sitioWebProland:number;
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SITIO_WEB_PROLAND_FIELD_NAME_CODE).then((reponse)=>{
         
            sitioWebProland = reponse.getData; 
            if(sitioWebProland == 1){
      
                BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SITIO_WEB_PROLAND_FIELD_NAME_CODE,"1").then((response2)=>{ 
               
                    const linkAcceso = response2.getData.getProtocol+"://"+response2.getData.getServer+":"+response2.getData.getPort+""+response2.getData.getUrl;
                    const sitioWebProlandList =                        
                    {
                       position: 5, 
                       name: "Proland",
                       open_new_tab: true,
                       path:linkAcceso,
                     
                       iconClass: "nav-icon fas fa-tint"
                   }
            
                   this.menu.push(

                    sitioWebProlandList


                   ) 
                   
                   this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                   this.sortItemList();                   

                }).catch(error => {
                    console.log(error)
                });
            }

        }).catch(error => {

            sitioWebProland = null;
        
        });



        let programaFidelizacionAdministracion:number;
        let programaFidelizacionClientes:number;
        
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE).then((reponse)=>{
            
            programaFidelizacionAdministracion = reponse.getData; 

            AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE).then((reponse2)=>{
                
                programaFidelizacionClientes = reponse2.getData;

                if(programaFidelizacionAdministracion == 1 || programaFidelizacionClientes == 1){

                    BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE,"1").then((response3)=>{

                        BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE,"1").then((response4)=>{

                            const programaFidelizacionListChilden:any = [];
                            if(programaFidelizacionAdministracion == 1 && programaFidelizacionClientes == 1){

                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    programaFidelizacionListChilden.push({
                                        name: "Clientes",
                                        path: linkAcceso
                                    });
        
                                }

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    programaFidelizacionListChilden.push({
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                



                            }else
                            if(programaFidelizacionAdministracion == 1){

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    programaFidelizacionListChilden.push({
                                        position: 8, 
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                   
         
                            }else{
                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    programaFidelizacionListChilden.push({
                                        position: 7, 
                                        name: "Clientes",
                                        path: linkAcceso
                                    });
        
                                }         
         

                            }      
                            
                            
                            const programaFidelizacionList =                        
                            {
                               position: 6, 
                               name: "Programa Fidelización",
                               
                               children: programaFidelizacionListChilden,
                               iconClass: "nav-icon fas fa-map"
                           }
             
                           this.menu.push(
       
                               programaFidelizacionList
       
       
                           )                            
                           this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                           this.sortItemList();
                        
                        }).catch(error => {
                            console.log(error)
                        });
                        
                    }).catch(error => {
                        console.log(error)
                    
                    });


                    }

            


       
       
             }).catch(error => {

        
                programaFidelizacionClientes = null;
            
            });


   
        }).catch(error => {
   
            programaFidelizacionAdministracion = null; 
       
        });




        let sistemaRegistroGarantiaAmiciAdministracion:number;
        let sistemaRegistroGarantiaAmiciClientes:number;
        
        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_REGISTRO_GARANTIA_AMICI_ADMINISTRACION_FIELD_NAME_CODE).then((reponse)=>{
            
            sistemaRegistroGarantiaAmiciAdministracion = reponse.getData; 

            AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,ApiConfig.API_LIST[0].database.table_list.adm_permission.data.VALUE_ACCESO_SISTEMA_REGISTRO_GARANTIA_AMICI_FIELD_NAME_CODE).then((reponse2)=>{
                
                sistemaRegistroGarantiaAmiciClientes = reponse2.getData;

                if(sistemaRegistroGarantiaAmiciAdministracion == 1 || sistemaRegistroGarantiaAmiciClientes == 1){

                    BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_REGISTRO_GARANTIA_AMICI_FIELD_NAME_CODE,"1").then((response3)=>{

                        BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_REGISTRO_GARANTIA_AMICI_ADMINISTRACION_FIELD_NAME_CODE,"1").then((response4)=>{

                            const sistemaRegistroGarantiaAmiciListChilden:any = [];
                            if(sistemaRegistroGarantiaAmiciAdministracion == 1 && sistemaRegistroGarantiaAmiciClientes == 1){

                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        name: "Registro",
                                        path: linkAcceso
                                    });
        
                                }

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                



                            }else
                            if(sistemaRegistroGarantiaAmiciAdministracion == 1){

                                if(response4.getData != null && response4.getData != false){
    
                                    const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
        
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        position: 11, 
                                        name: "Administración",
                                        path: linkAcceso
                                    });
        
                                }                                   
         
                            }else{
                                if(response3.getData != null && response3.getData != false){
                            
                                    const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                                    
                                    sistemaRegistroGarantiaAmiciListChilden.push({
                                        position: 10, 
                                        name: "Registro",
                                        path: linkAcceso
                                    });
        
                                }         
         
                            }      
                            
                            
                            const sistemaRegistroGarantiaAmiciList =                        
                            {
                               position: 9, 
                               name: "Garantia AMICI",
                               
                               children: sistemaRegistroGarantiaAmiciListChilden,
                               iconClass: "nav-icon fas fa-map"
                           }
                        //   console.log("Septimo")
                           this.menu.push(
       
                            sistemaRegistroGarantiaAmiciList
       
       
                           )                            

                           this.quantityItemsCompletedLoad = this.quantityItemsCompletedLoad +1;
                           this.sortItemList();                        


                        }).catch(error => {
                            console.log(error)
                        });
                        
                    }).catch(error => {
                        console.log(error)
                    
                    });


                    }

            


       
       
             }).catch(error => {

        
                sistemaRegistroGarantiaAmiciClientes = null;
            
            });


   
        }).catch(error => {
   
            sistemaRegistroGarantiaAmiciAdministracion = null; 
       
        });
  


    }

                if(programaFidelizacionAdministracion == 1 || programaFidelizacionClientes == 1){

              //      console.log("Cuarto")
                   const programaFidelizacionListChilden:any = [];
                   if(programaFidelizacionAdministracion==1 && programaFidelizacionClientes==1){
                 //   console.log("Quinto")
                    BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_CLIENTES_FIELD_NAME_CODE,"1").then((response3)=>{

                       
                        if(response3.getData != null && response3.getData != false){
                            
                            const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;
                            
                            programaFidelizacionListChilden.push({
                                name: "Clientes",
                                path: linkAcceso
                            });

                        }


                        BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE,"1").then((response4)=>{

                            if(response4.getData != null && response4.getData != false){
    
                                const linkAcceso = response4.getData.getProtocol+"://"+response4.getData.getServer+":"+response4.getData.getPort+""+response4.getData.getUrl;
    
                                programaFidelizacionListChilden.push({
                                    name: "Administración",
                                    path: linkAcceso
                                });
    
                            }
    
    
    
                        }).catch(error => {
                            console.log(error)
                        
                        });



                    }).catch(error => {
                   console.log(error)
                    
                    });




                   }else






                   if(programaFidelizacionClientes==1){
                  //  console.log("Sexto")
                    BsnExternalSystemImplementationService.bsnExternalSystemImplementationByNameCodeIdBsnExternalSystemAndCodeIdBsnExternalSystem(ApiConfig.API_LIST[0].database.table_list.bsn_external_system.data.VALUE_SISTEMA_FIDELIZACION_ADMINISTRACION_FIELD_NAME_CODE,"1").then((response3)=>{

                        if(response3.getData != null && response3.getData != false){

                            const linkAcceso = response3.getData.getProtocol+"://"+response3.getData.getServer+":"+response3.getData.getPort+""+response3.getData.getUrl;

                            programaFidelizacionListChilden.push({
                                name: "Administración",
                                path: linkAcceso
                            });

                        }



                    }).catch(error => {
                        console.log(error)
                    
                    });


                   }

                    const programaFidelizacionList =                        
                     {
                        name: "Programa fidelización",
                        
                        children: programaFidelizacionListChilden,
                        iconClass: "nav-icon fas fa-university"
                    }
                 //   console.log("Septimo")
                    this.menu.push(

                        programaFidelizacionList


                    )


                }*/        

/*

        let imprimirEtiqueta:number;

        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,"imprimir_etiqueta").then((reponse)=>{


            imprimirEtiqueta = reponse; 

            if(imprimirEtiqueta == 1){

                const utilityListChilden:any = [];
                utilityListChilden.push({
                    name: "Imprimir Label",
                    path: '/utility/print_label'
                });

                const utilityList =                        
                {
                   name: "Utilidades",
                   children: utilityListChilden,
                   iconClass: "nav-icon fas fa-tools"
               }

               this.menu.push(

                utilityList


               )

            }

     
        }).catch(error => {
   
            imprimirEtiqueta = null; 
       
        });


        let verListaProductoTipoCliente:number;

        AdmUserAdmPermissionService.idAdmUserHasNameCodeAdmPermission(idAdmUser,"ver_lista_producto_tipo_cliente").then((reponse)=>{


            verListaProductoTipoCliente = reponse; 

            if(verListaProductoTipoCliente == 1){

                const utilityListChilden:any = [];
                utilityListChilden.push({
                    name: "Lista",
                    path: '/product_customer_category/list'
                });

                const utilityList =                        
                {
                   name: "Productos y Clientes",
                   children: utilityListChilden,
                   iconClass: "nav-icon fas fa-flag"
               }

               this.menu.push(

                utilityList


               )

            }

     
        }).catch(error => {
   
            imprimirEtiqueta = null; 
       
        });*/

