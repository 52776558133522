import {IAuthState} from '@/interfaces/state';
import { AdmUser } from '@/services/access_api/models/api/1/db/AdmUser';
//import {IUser} from '@/interfaces/user';

export default {
    setToken: (state: IAuthState, payload: string): void => {
        state.token = payload;
    },
    setUser: (state: IAuthState, payload: AdmUser): void => {
        state.user = payload;
    }
};
